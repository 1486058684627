import React from "react";
import { ParallaxProvider } from "react-skrollr";
import Footer from "./common/footer";
import NavBar from "./common/nav";

export default function Home() {
  return (
    <React.Fragment>
      <NavBar />
      <div className="offcanvas-wrap">
        <section className="overflow-hidden py-15 py-xl-20">
          <div className="container">
            <div className="row align-items-end mb-10 mt-5">
              <div className="col-lg-8">
                <h1>We are a label and collective for South Asian artistry.</h1>
              </div>
            </div>
          </div>
          <div className="container gallery-1" data-aos="fade-up">
            <ParallaxProvider
              init={{
                mobileCheck: function () {
                  return false;
                },
                forceHeight: false,
              }}
            >
              <div
                className="row flex-nowrap align-items-end g-2 g-xl-5 mb-2 mb-xl-5"
                data-bottom-top="transform: translateX(-50%)"
                data-top-bottom="transform: translateX(-100%)"
              >
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/2.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/2.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/3.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/3.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/14.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/14.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/6.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/6.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/13.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/13.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/16.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/16.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
              </div>
              <div
                className="row flex-nowrap align-items-end g-2 g-xl-5 mb-2 mb-xl-5"
                data-bottom-top="transform: translateX(-100%)"
                data-top-bottom="transform: translateX(-50%)"
              >
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/9.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/9.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/7.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/7.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/12.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/12.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/10.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/10.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/5.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/5.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
                <div className="col-6 col-lg-5">
                  <figure
                    className="media equal equal-16-10 media-image"
                    data-bp={{
                      imgSrc: require("../images/events/15.jpg").default,
                      parentGalleryClass: "gallery-1",
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${
                          require("../images/events/15.jpg").default
                        })`,
                      }}
                    ></span>
                  </figure>
                </div>
              </div>
            </ParallaxProvider>
          </div>
        </section>

        <section className="pb-15 pb-xl-20">
          <div className="container">
            <div className="row mb-10">
              <div className="col-lg-10">
                <span className="eyebrow text-primary mb-4">
                  Crafting more examples for the next generation
                </span>
                <h3 className="fs-4">
                  We are building bridges for South Asian artists and brands through premium, cohesive offerings in film & television, digital media, music and creative services.
                </h3>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
}
