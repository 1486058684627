import React from "react";

export default function NavBar() {
  return (
    <nav
      id="mainNav"
      className="navbar navbar-expand-lg navbar-sticky navbar-light"
    >
      <div className="container">
        <a href="/" className="navbar-brand">
          <img
            src={require("../../images/logo/nonresidentLogoBlack.svg").default}
            alt="Logo"
          />
        </a>
        <ul className="navbar-nav navbar-nav-secondary order-lg-3">
          <li className="nav-item d-lg-none">
            <a
              className="nav-link nav-icon"
              href="/"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </a>
          </li>
          <li className="nav-item d-none d-lg-block">
            <a
              href="http://eepurl.com/gNOP6r"
              target="_blank"
              className="btn btn-outline-light rounded-box ms-2"
            >
              Get Access
            </a>
          </li>
        </ul>
        <div
          className="collapse navbar-collapse"
          id="navbar"
          data-bs-parent="#mainNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#" role="button">
                What We Do
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" role="button">
                Explore
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" role="button">
                Experiences
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" role="button">
                News
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" role="button">
                Projects
              </a>
            </li>
            <li className="nav-item d-lg-none">
              <a
                href="http://eepurl.com/gNOP6r"
                target="_blank"
                className="nav-link text-primary"
              >
                Get Access
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
