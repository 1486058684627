import "./js/index.js";
import "./scss/index.scss";

import Routes from "./components/routes";

function App() {
  return <Routes />;
}

export default App;
