import React from "react";
import Footer from "./common/footer";
import NavBar from "./common/nav";

export default function Artist() {
  return (
    <React.Fragment>
      <NavBar />

      <div>
        <section>
          <div class="d-flex flex-column container min-vh-100 py-20">
            <div class="row align-items-center justify-content-center justify-content-lg-between my-auto">
              <div class="col-lg-6 order-lg-2">
                <img class="img-fluid" src={
                  require("../images/svg/404.svg")
                    .default
                } alt="404"/>
              </div>
              <div class="col-md-8 col-lg-5 order-lg-1 text-center text-lg-start">
                <h1 class="display-2">The page you are looking for doesn't exist or has been moved.</h1>
                <a href="/" class="btn btn-outline-light rounded-box">Go back to homepage</a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </React.Fragment>
  );
}
