import React from "react";

export default function Footer() {
  return (
    <footer className="py-15 py-xl-20 border-top">
      <div className="container">
        <div className="row g-2 g-lg-6 mb-8">
          <div className="col-lg-6">
            <h5>New York x Los Angeles x Delhi x Mumbai</h5>
          </div>
          <div className="col-lg-6 text-lg-end">
            <span className="h5">+1 (347) 735 8862</span>
          </div>
        </div>
        <div className="row mb-8">
          <div className="col">
            <hr />
          </div>
        </div>
        <div className="row g-0 g-lg-6 text-secondary">
          <div className="col-lg-6 text-lg-end order-lg-2">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  href="https://instagram.com/nonresident.fm"
                  className="text-reset"
                >
                  instagram
                </a>
              </li>
              <li className="list-inline-item ms-1">
                <a
                  href="https://twitter.com/nonresidentfm"
                  className="text-reset"
                >
                  twitter
                </a>
              </li>
              <li className="list-inline-item ms-1">
                <a
                  href="https://linkedin.com/company/nonresident"
                  className="text-reset"
                >
                  linkedin
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 order-lg-1">
            <p>© 2021 NONRESIDENT</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
