import React from "react";
import Footer from "./common/footer";
import NavBar from "./common/nav";
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";

export default function Artist() {
  window.onload = function () {
    const carousel = document.querySelectorAll("[data-carousel]");
    carousel.forEach(function (element) {
      const settings = JSON.parse(element.dataset.carousel);
      settings.container = element;
      settings.controlsText = [
        '<i class="bi bi-arrow-left"></i>',
        '<i class="bi bi-arrow-right"></i>',
      ];
      tns(settings);
    });
  };

  return (
    <React.Fragment>
      <NavBar />

      <div className="offcanvas-wrap">
        <section className="eventSection">
          <div className="container mt-10">
            <div className="row g-5 justify-content-center justify-content-lg-between">
              <div className="col-lg-6 position-relative">
                <div className="row g-1">
                  <div className="col-md-10 order-md-2">
                    <div className="carousel">
                      <div data-carousel='{"mouseDrag": true, "navContainer": "#nav-1", "gutter": 8, "loop": true, "items": 1}'>
                        <div className="item">
                          <img
                            src={
                              require("../images/artists/raaginder/raaginderRockwoodFlyer.jpg")
                                .default
                            }
                            alt="Artist"
                          />
                        </div>

                        <div className="item">
                          <img
                            src={
                              require("../images/artists/raaginder/raaginder1.jpeg")
                                .default
                            }
                            alt="Artist"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 order-md-1 mobileHide">
                    <div
                      className="carousel-thumbs d-flex flex-row flex-md-column"
                      id="nav-1"
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src={
                            require("../images/artists/raaginder/raaginderRockwoodFlyer.jpg")
                              .default
                          }
                          alt="Artist"
                        />
                      </div>
                      <div>
                        <img
                          className="img-fluid"
                          src={
                            require("../images/artists/raaginder/raaginder1.jpeg")
                              .default
                          }
                          alt="Artist"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-5">
                <h6 className="fw-bold">Sunday, Nov 7, 2021</h6>
                <h1 className="mb-1">Raaginder</h1>
                <div className="fs-5 mb-3">w/ NY Virtuoso</div>
                <p className="fs-6 mb-3">
                  Rockwood Music Hall , Stage 2 (AGES 21+)
                  <br />
                  196 Allen St, New York, NY, 10002
                </p>

                <div className="row g-1 align-items-center mb-5">
                  <div className="col">
                    <div className="d-grid">
                      <a
                        href="https://www.seetickets.us/event/Raaginder/449088?ref=nonresident.com"
                        target="_blank"
                        className="btn btn-primary btn-lg rounded-box"
                      >
                        Buy Tickets - $20
                      </a>
                    </div>
                  </div>
                </div>

                <p className="text-secondary">
                  A very limited number of bar stools and balcony table seats
                  are available on a first come first served basis. <br />
                  <br />
                  There is a one drink minimum per show *standing*
                  <br />
                  <br />
                  There is a two drink minimum per show *seated*
                  <br />
                  <br />
                  Current New York City guidelines may restrict access to this
                  event. Please visit Rockwood Music Hall's{" "}
                  <a
                    href="https://rockwoodmusichall.com/covid-19/"
                    target="_blank"
                  >
                    COVID-19 Information page
                  </a>{" "}
                  for the most updated information regarding vaccine, testing,
                  and mask requirements.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </React.Fragment>
  );
}
